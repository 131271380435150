.container {
  display: flex;
  height: 100vh;

  .banner {
    width: 550px;
    background: linear-gradient(163.85deg, #1d2129 0%, #00308f 100%);
  }

  .content {
    flex: 1;
    position: relative;
    padding-bottom: 40px;
  }
}


.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  height: 100%;

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-fill-1);
  }

  &-sub-title {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-3);
  }

  &-image {
    margin-top: 30px;
    width: 320px;
  }
}

.login-form {
  &-wrapper {
    width: 320px;
  }

  &-title {
    font-size: 24px;
    font-weight: 500;
    color: var(--color-text-1);
    line-height: 32px;
  }

  &-sub-title {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-3);
  }

  &-error-msg {
    height: 32px;
    line-height: 32px;
    color: rgb(var(--red-6));
  }

  &-password-actions {
    display: flex;
    justify-content: space-between;
  }

  &-register-btn {
    color: var(--color-text-3) !important;
  }
}
