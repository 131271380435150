@import '@arco-themes/react-arco-pro/index.less';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
}

.arco-icon {
  width: 1.3em;
  height: 1.3em;
  vertical-align: -4px;
}

.arco-menu .arco-menu-item .arco-icon,
.arco-menu .arco-menu-group-title .arco-icon,
.arco-menu .arco-menu-pop-header .arco-icon,
.arco-menu .arco-menu-inline-header .arco-icon {
  margin-right: 10px;
}